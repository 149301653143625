import React from "react"
import { graphql } from "gatsby"
import { Container } from "react-bootstrap"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default function Template({
  data, // this prop will be injected by the GraphQL query we'll write in a bit
  pageContext, 
  location
}) {
  const {
    breadcrumb: { crumbs },
  } = pageContext

  const { markdownRemark: post } = data // data.markdownRemark holds your post data
  const pageTitle = post.frontmatter.title
  const imageURL = 'http://www.tlrc.space' + post.frontmatter.featuredImage.childImageSharp.fluid.src
  const featuredImgFluid = post.frontmatter.featuredImage.childImageSharp.fluid
  
  const hiddenCrumbs= ["/news/1995", "/news/1995/01", "/news/1995/07", "/news/2020", "/news/2020/04"]

  return (
    <Layout pageInfo={{ pageName: pageTitle }} crumbs={crumbs} location={location} crumbLabel={pageTitle} hiddenCrumbs={hiddenCrumbs}>
      <SEO title={post.frontmatter.title} keywords={[`gatsby`, `react`, `bootstrap`]} description={post.frontmatter.description} image={imageURL} />
      <Container fluid className="pt-3 pl-5 pr-5">
        <Img 
              fluid={featuredImgFluid} 
              style={{ margin: '0', display: 'block', maxWidth: '500px', border: '1px solid #000' }}
              imgStyle={{ objectFit: 'contain' }}
            />
        <h1 className="news-heading">{pageTitle}</h1>

            <div>
              <div
                className="news-content"
                dangerouslySetInnerHTML={{ __html: post.html }}
              />
            </div>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        description
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
              src
            }
          }
        }
      }
    }
  }
`